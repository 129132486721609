import packageJson from '../../package.json';
import { Environment } from './interfaces';

export const environment: Environment = {
    production: true,
    version: packageJson.version,
    federation: {
        manifest: '/assets/federation.manifest.json',
    },
    chatbot: {
        chatBase: '/assets/scripts/chatbase/chatbase.js',
    },
    configPath: 'config-prod.json',
    imports: [],
};
